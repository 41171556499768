import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '@dendra/config';
import { SharedComponentsModule } from '@shared/components';

@Component({
  selector: 'mydendra-not-exists',
  templateUrl: './not-exists.component.html',
  styleUrl: './not-exists.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, SharedComponentsModule],
})
export class NotExistsComponent {
  baseUrl = environment.backend_root;
}
